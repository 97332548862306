//main.js主要是用来导入模块，通过多个vue实例，把vue模块挂载到html页面。html要挂几个vue，就要new几个vue。

//import导入的模块，要么在node_modules（npm安装的），要么在./本地。
//npm安装导入
import { createApp } from "vue";
import axios from 'axios'
import VueAxios from 'vue-axios'
//bootstrap_vue不能用了，主页消失了，不支持vue3。
//import bootstrap_vue from "bootstrap-vue";
//动态修改标签
//import vue_meta from "vue-meta";
//选择复制
//import vue_clipboard2 from 'vue-clipboard2';
//import 'font-awesome/css/font-awesome.min.css';

//本地导入
import "../../assets/css/style.css";

//router和store的名字不能改，否则建立vue实例不成功。
import global from "./global";
import router from "./router";
import store from "./store";

import header from "../../components/header.vue";
import navbar from "../../components/navbar.vue";
import carousel from "../../components/carousel.vue";
//import pics from "../../components/pics.vue";
import blogroll from "../../components/blogroll.vue";
import footer from "../../components/footer.vue";
//获取url的参数
//import utils from './get_url'; //获取url参数

//百度统计开始
/*
var _hmt = _hmt || [];
window._hmt = _hmt;
(function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?c2a48c901a0463f613c4c51ea34e977b";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();
router.beforeEach((to, from, next) => {
    if (_hmt) {
        if (to.path) {
            _hmt.push(['_trackPageview', '/#' + to.fullPath]);
        }
    }
    next();
});
*/
//百度统计结束

//每个 vue 应用都是通过用 vue 函数创建一个新的 （导入的）vue 实例开始的。
//给home_app模块，提供了rooter、store和render服务。比如，进行$store.commit。
const app_header = createApp(header, {axios:axios});
app_header.use(router).use(store).use(VueAxios, axios).mount("#header");

const app_navbar = createApp(navbar, {axios:axios});
app_navbar.config.globalProperties.$global = global;
app_navbar.config.globalProperties.$router = router;
app_navbar.config.globalProperties.$axios = axios;
app_navbar.config.globalProperties.$store = store;
app_navbar.use(router).use(store).use(VueAxios, axios).mount("#navbar");

const app_carousel = createApp(carousel, {axios:axios});
app_carousel.config.globalProperties.$global = global;
app_carousel.config.globalProperties.$axios = axios;
app_carousel.use(router).use(store).use(VueAxios, axios).mount("#carousel");

//vue3的时候，后端推送不能给加载空的vue。
// const app_django_dtl = createApp(django_dtl, {axios:axios});
// app_django_dtl.use(router).use(store).use(VueAxios, axios).mount("#django_dtl");

const app_blogroll = createApp(blogroll, {axios:axios});
app_blogroll.use(router).use(store).use(VueAxios, axios).mount("#blogroll");

const app_footer = createApp(footer, {axios:axios});
app_footer.use(router).use(store).use(VueAxios, axios).mount("#footer");

