<!--轮播-->
<template>
	
	<!-- bootstrap4结合轮播图片，可以参考：https://segmentfault.com/q/1010000018060631/a-1020000018062007 -->
	<div id="carousel_section">
	
	<div id="id_carousel" class="carousel slide" data-ride="carousel">
	 
		<!-- 指示符 -->
		<ul class="carousel-indicators">
			<li data-target="#id_carousel" data-slide-to="0" class="active"></li>
			<li data-target="#id_carousel" data-slide-to="1"></li>

		</ul>

		<!-- 轮播图片 -->
		<div class="carousel-inner">

			<div class="carousel-item active">
				<!-- 用链接包裹img，代替img，可以打开图片。 -->
<!-- 				<a href="https://www.qingju.com.cn/media/pics/%E9%A6%96%E9%A1%B5%E5%9B%BE.png" target="_blank">
						<img src="https://www.qingju.com.cn/media/pics/%E9%A6%96%E9%A1%B5%E5%9B%BE.png"/>
				</a> -->
				<img src="https://www.qingju.com.cn/media/pics/%E9%A6%96%E9%A1%B5%E5%9B%BE.png">
			</div>
			
			<div class="carousel-item">
<!-- 				<a href="https://www.qingju.com.cn/media/pics/%E5%85%A8%E5%9C%BA%E6%99%AF.png" target="_blank">
						<img src="https://www.qingju.com.cn/media/pics/%E5%85%A8%E5%9C%BA%E6%99%AF.png"/>
				</a> -->
				<img src="https://www.qingju.com.cn/media/pics/%E5%85%A8%E5%9C%BA%E6%99%AF.png">
			</div>

		</div>

		<!-- 左右切换按钮 -->
		<a class="carousel-control-prev" href="#id_carousel" data-slide="prev">
			<span class="carousel-control-prev-icon"></span>
		</a>
		
		<a class="carousel-control-next" href="#id_carousel" data-slide="next">
			<span class="carousel-control-next-icon"></span>
		</a>
	 
	</div>

	</div>

</template>

<script>
    export default {
        //这个group，如果html用下划线形式，这里要用驼峰形式。所以，简单点好。
        //props: ['group'],

        data() {
            return {

                pics: [],

                num: 0
            }
        },

        computed: {

        },

        mounted() {
   //          var self = this;
   //          console.log(this.group);
			// console.log(self.group);
   //          self.$axios.get(this.$global.BASE_URL + 'api/all_group_pic/' + self.group + '/').then(res => {
   //              if (res.data.count > 0) {
   //                  self.pics = res.data.results;
   //                  console.log(self.pics);
   //                  self.num = self.pics.length;
			// 		console.log(self.num);
   //              }
   //              else{
   //                  console.log("no pics.");
   //              }
   //          }).catch(err => {
   //              return JSON.stringify(err.response.data.description);
   //          });
        },

        methods: {

        }

    };
</script>