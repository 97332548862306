<!--页脚-->
<template>
    <div>

		<!-- bootstrap多媒体，左右对齐似乎更好做。 -->
        <div class="media my-3">

            <div class="">
                <h4 class="text-primary">
					<img src="../assets/img/log_youqinglianjie.png" alt="友情链接" class="img-fluid" width="25" height="25" />
					<span class="mx-2">友情链接</span>
				</h4>
            </div>
			
			<div class="media-body">
			    <hr class="mx-5">
			</div>


        </div>
		
		
        <!--bootstrap导航-->
        <ul class="nav justify-content-left" style="font-size: 20px;">

			<li class="nav-item">
				<a href="https://isn.xidian.edu.cn/" target="_blank">
						<img src="../assets/img/log_shiyanshi.jpg" alt="综合业务网理论及关键技术国家重点实验室" class="img-fluid"/>
				</a>
			</li>

			<li class="nav-item ms-5">
				<a href="https://www.xidian.edu.cn/" target="_blank">
						<img src="../assets/img/log_xidian.jpg" alt="西安电子科技大学" class="img-fluid" width="300" height="50"/>
				</a>
			</li>

        </ul>

    </div>

</template>

<script>
    export default {
        data() {
            return {
                year: ''
            }
        },
        computed: {

        }

    }
</script>